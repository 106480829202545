.display-story-component {
    font-family: 'Roboto Slab', serif;
    padding-top: 5%;
}

.prompt-details-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1.5fr 1fr;
    grid-template-rows: 0.2fr 0.2fr 1fr;
    gap: 1px 1px;
    grid-template-areas: 
        "ds-social-media . . ds-options"
        "ds-image ds-image ds-title ds-title" 
        "ds-image ds-image . ds-date-created";
    padding-bottom: 3%;
}

.ds-social-media {
    grid-area: ds-social-media;
    display: flex;
    justify-content: flex-start;
    padding-bottom: 10px;
}
  
.ds-title { 
    grid-area: ds-title;
    margin-left: 5%;
    font-size: 1.5em;
}

.ds-title > h1::first-letter {
    text-transform: uppercase;
}

.ds-image { 
    grid-area: ds-image;
}

.display-image {
    max-width: 100%;
    max-height: 100%;
}

.ds-date-created {
    grid-area: ds-date-created;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: bold;
    color: #6894A0;
    padding-right: 3%;
}

.dropleft {
    display: flex;
    justify-content: flex-end;
}

.dropdown-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
}

.dropdown-menu {
    border: 1px solid rgb(178, 206, 214);
    border-radius: 0;
    font-size: 0.9em;
    height: 35px;
    padding: 0;
}

.dropdown-item {
    padding-left: 10px;
    cursor: pointer;
    margin-top: 1%;
    height: 30px;
}

.ds-options { 
    grid-area: ds-options;
    display: flex;
    justify-content: flex-end;
    align-items: center;
 }

.entry-grid-container {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr auto;
    gap: 1px 1px;
    grid-template-areas: 
        "ds-text ds-text ds-text"
        "ds-text ds-text ds-text"
        "ds-likes ds-author ds-author";
    background-color: #ffffff;
    padding: 5%;
    margin-bottom: 5%;
    box-shadow: 5px 5px 5px rgb(190, 190, 190);
  }
  
.ds-text { 
    grid-area: ds-text;
    border-bottom: 1px solid rgb(233, 233, 233);
    padding-bottom: 20px;
}

.ds-text > p {
    margin: 3% 0;
    word-wrap: break-word; //TODO not ideal but will do for now, may cause line breaks to appear in weird places
}

.ds-text::first-letter {
    text-transform: uppercase;
    float: left;
    font-size: 2.9em;
    line-height: 55px;
    margin-top: -6px;
    padding-right: 5px;
    padding-left: 5px;
}

.ds-likes { 
    grid-area: ds-likes;
    align-self: flex-end;
    font-family: 'Roboto Slab', serif;
    font-weight: bold;
 }

.ds-author { 
    font-size: 1em;
    grid-area: ds-author;
    text-align: end;
    color: rgb(161, 168, 168);
    font-family: 'Pacifico', cursive;
    padding-top: 20px;
    padding-right: 30px;
 }

 .ds-entry-created {
    font-family: 'Roboto Slab', serif;
    font-size: 0.6em;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 20px;
 }

 .ds-last-author-warning { 
    font-size: 1.3em;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #1282A2;
    font-family: 'Roboto', serif;
 }

.ds-story-stats {
    font-family: 'Roboto', serif;
    font-size: 0.9em;
    font-weight: bold;
    color: #1282A2;
}

.join-story-btn {
    background-color: #1282A2;
    color: #ffffff;
    font-family: 'Pacifico', serif;
    // font-weight: bold;
    font-size: 1.2em;
    padding: 10px 15px;
    margin-top: 30px;
}

.btn-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
}

@media only screen and (max-width: 800px) {
    .prompt-details-grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto auto auto;
        gap: 1px 1px;
        grid-template-areas: 
            "ds-social-media ds-options"
            ". ds-date-created"
            "ds-image ds-image" 
            "ds-title ds-title";
        padding-bottom: 5%;
    }

    .ds-social-media {
        padding: 10px 0;
    }

    .ds-image {
        max-width: 100%;
        padding: 10px 0;
    }

    .ds-title {
        font-size: 1em;
    }

}