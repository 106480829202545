#app-title {
    color: #1282A2;
    font-family: 'Pacifico', cursive;
    font-size: 1.6em;
    align-self: center;
}

.menu-icon {
    color: #034078;
}

#AppBar {
    background-color: rgb(255, 255, 255);
}

#navbar {
    margin-bottom: 75px;
}

.navbar-link {
    color: #034078;
    font-size: 0.7em;
    text-align: end;
}

a {
    text-decoration: none;
    color: #B1B1B8;
}

a:hover {
    text-decoration: none;
    color: #000;
}

.side-link:hover {
    color: #000;
}

@media only screen and (max-width: 600px) {
    #logo {
        margin-left: -16px;
    }
    
    #home-link {
        display: none;
    }
}