#preview {
  font-family: "Roboto Slab", serif;
}

.details {
  font-size: 0.8em;
}

#heart {
  font-size: small;
}

.read-more {
  align-self: flex-end;
}

.greeting {
  color: #1282a2;
  font-family: "Pacifico", cursive;
  align-self: center;
  text-align: center;
  margin-top: 5%;
  margin-bottom: 5%;
  font-size: 1.8em;
}
