.sp-grid-container {
  border: 1px rgb(213, 231, 230) solid;
  border-radius: 2px;
  box-shadow: 5px 5px 5px rgb(190, 190, 190);
  background-color: #ffffff;
  font-size: 0.9em;
  font-family: "Roboto Slab", serif;
  color: rgb(50, 121, 109);
  display: grid;
  grid-template-columns: 1.3fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr;
  grid-template-rows: 0.8fr 2.5fr 0.2fr;
  gap: 1px 1px;
  grid-template-areas:
    "sp-image sp-title sp-title sp-title sp-title sp-author"
    "sp-image sp-preview-text sp-preview-text sp-preview-text sp-preview-text sp-preview-text"
    ". sp-genre sp-collab sp-entries sp-likes sp-date-created";
  margin-bottom: 2%;
  padding: 2%;
  padding-top: 1%;
}

.sp-image {
  grid-area: sp-image;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sp-story-image {
    max-width: 100%;
    max-height: 100%;
}

.sp-title { 
    align-self: flex-end;
    grid-area: sp-title;
    font-size: 1.2em;
    font-weight: bold;
    margin-left: 3%;
}

.sp-author-name {
  grid-area: sp-author;
  font-weight: 300;
  font-family: "Pacifico", cursive;
  font-size: 1.2em;
}

.sp-title,
.sp-author {
  border-bottom: 1px solid rgb(213, 231, 230);
  padding-bottom: 10px;
  align-self: flex-end;
}

.sp-author {
  padding-left: 5%;
}

.sp-prompt-text {
  font-size: 0.75em;
}

.sp-preview-text { 
    grid-area: sp-preview-text;
    margin-left: 3%;
}

.sp-genre {
  grid-area: sp-genre;
}

.sp-likes {
  grid-area: sp-likes;
}

.sp-collab {
  grid-area: sp-collab;
}

.sp-entries {
  grid-area: sp-entries;
}

.sp-genre,
.sp-likes,
.sp-collab,
.sp-entries,
.sp-date-created {
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: 500;
  color: rgb(140, 182, 175);
  padding-left: 10%;
  text-align: center;
}

.sp-date-created {
  grid-area: sp-date-created;
  color: rgb(176, 180, 180);
  font-size: 0.7em;
}

.sp-heart-icon {
    height: 1.1em;
    width: 1.1em;
    margin-bottom: -2px;
}

@media only screen and (max-width: 800px) {
  .sp-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 3fr 1fr auto 1fr 0.3fr 0.3fr 0.3fr;
    gap: 1px 1px;
    grid-template-areas:
      "sp-image sp-image"
      "sp-title sp-title"
      "sp-preview-text sp-preview-text"
      "sp-author sp-author"
      "sp-genre sp-likes"
      "sp-collab sp-entries"
      "sp-date-created sp-date-created";
    padding: 4%;
    margin-bottom: 5%;
  }

  .sp-image {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .sp-title {
    margin-top: 0%;
  }

  .sp-author {
    grid-area: sp-author;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin: 5% 0 5% 0;
    padding-left: 10px;
  }

  .sp-author-name {
    margin-left: 2%;
    font-size: 1em;
  }

  .sp-genre,
  .sp-likes,
  .sp-collab,
  .sp-entries,
  .sp-date-created {
  font-size: 0.7em;
  padding-left: 10px;
  }

  .sp-date-created {
    color: rgb(176, 180, 180);
    padding-top: 10px;
  }
}
