.grid-container {
  border: 1px rgb(213, 231, 230) solid;
  border-radius: 2px;
  box-shadow: 5px 5px 5px rgb(190, 190, 190);
  background-color: #ffffff;
  font-size: 0.9em;
  font-family: "Roboto Slab", serif;
  color: rgb(50, 121, 109);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 0.8fr 2.5fr 0.2fr;
  gap: 1px 1px;
  grid-template-areas:
    "image title title author"
    "image preview-text preview-text preview-text"
    ". genre collab likes";
  margin-bottom: 2%;
  padding: 2%;
  padding-top: 1%;
}

.image {
  grid-area: image;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.story-image {
  max-width: 200px;
  max-height: 200px;
}

.title {
  align-self: flex-end;
  grid-area: title;
  font-size: 1.2em;
  font-weight: bold;
}

.author-name {
  grid-area: author;
  font-size: 0.8em;
  font-weight: bold;
}

.title,
.author {
  border-bottom: 1px solid rgb(213, 231, 230);
  padding-bottom: 10px;
  align-self: flex-end;
}

.prompt-text {
  font-size: 0.75em;
}

.preview-text {
  grid-area: preview-text;
}

.genre {
  grid-area: genre;
}

.likes {
  grid-area: likes;
}
.heart {
  height: 15px;
  width: 15px;
}

.collab {
  grid-area: collab;
}

.genre,
.likes,
.collab {
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: 500;
  color: rgb(116, 151, 145);
}

@media only screen and (max-width: 600px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto 1fr 0.3fr 0.3fr;
    gap: 1px 1px;
    grid-template-areas:
      "image image"
      "title title"
      "preview-text preview-text"
      "author author"
      "genre likes"
      "collab .";
    padding: 4%;
    margin-bottom: 5%;
  }

  .image {
    grid-area: image;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    grid-area: title;
    margin-top: 2%;
  }

  .preview-text {
    grid-area: preview-text;
  }

  .author {
    grid-area: author;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  .author-name {
    margin-left: 2%;
  }

  .genre {
    grid-area: genre;
  }

  .likes {
    grid-area: likes;
  }

  .collab {
    grid-area: collab;
  }
}
